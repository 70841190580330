@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:'Poppins';
}

body {
  background-color: #f0f0f0;
  margin-top: 0;
  height: 100vh;
  background-image: url("../public/images/snake_background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main_heading {
  font-weight: bold;
  padding: 8px 0px;
}

.frame {
  background-color: transparent;
  width: 500px;
  height: 600px;
  border: none;
}
.pageNotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 1rem;
  color: white; /* The text color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6),
    /* Right and bottom shadow */ -2px -2px 4px rgba(0, 0, 0, 0.6),
    /* Left and top shadow */ 2px -2px 4px rgba(0, 0, 0, 0.6),
    /* Top and right shadow */ -2px 2px 4px rgba(0, 0, 0, 0.6); /* Bottom and left shadow */
}
.details_body{
  padding-top: 100px;
  background-color: #F0F0F0;
  display: flex;
  justify-content: center;

}
.details_container{
  width: 60%;
  text-align: justify;
}
.details_container h2{
  font-size: 24px;
  font-weight: 600;
}
.details_container p ,.details_container li {
  font-size: 18px;
}
.details_container ul{
  list-style:inside;
}

@media (max-width: 768px) {
  body {
    background: radial-gradient(#9fb807 40%, #81a100);
    height: 100%;
  }

  .frame {
    background-color: transparent;
    width: 100%;
    height: 600px;
  }
  .details_container{
    width: 85%;
    text-align:start;
  }
}
